// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outstanding {
  width: 100px;
  height: 40px;
}

.paid {
  width: 100px;
}

.balance-row {
  margin: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 15px;
}

.pay-container {
  height: 150px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0px;
  border-radius: 20px 20px 0px 0px !important;
  padding-top: 1em;
}

.balance-scroll-container {
  /* height: 72vh; */
  overflow: scroll;
  margin-bottom: 100px;
}

.material-icons {
  font-size: 30px;
  height: 30px;
  width: 30px;
}

.icon-align {
  display: block;
  margin: auto;
}

.material-inventory-icon {
  font-size: 30px;
  height: 30px;
  width: 30px;
}

.color-red {
  color: red;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.simple-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* vagy a kívánt magasság */
  text-align: center;
}

.relative {
  position: relative;
}

.exit-modal {
  width: 100%;
  margin-left: calc(100% - 1rem);
  font-weight: bold;
}
.exit-modal span {
  padding: 0.25rem;
}
.exit-modal span:hover {
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 0.25rem;
  outline: 1px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.1);
}

.inforamtion {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  text-align: justify;
}

.button-design {
  width: 100%;
  text-align: center;
  padding-top: 1em;
}

a {
  cursor: pointer;
  color: red;
  -webkit-text-decoration: underline red;
          text-decoration: underline red;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

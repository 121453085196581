export const environment = {
  production: false,
  home: false,
  topBarHeight: 50,
  bottomBarHeight: 50,
  diagnostic: true,
  hwComputerVisionUrl: "https://exi.hostwaremobile.com/api/exi/photo",
  exiBackendUrl: "https://exi.hostwaremobile.com",
  apiHost: 'https://exi.hostwaremobile.com',
  apiPort: '',
  isNewPhoto: false,
  chatBackendUrl: 'https://cht.hostwaremobile.com'
};
// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-radio-label {
  width: 100%;
}

.mat-radio-label-content {
  width: 100%;
}

td.mat-cell {
  border-bottom-style: none;
}

.mat-header-row {
  display: none;
}

th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
  padding-right: 0px;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 0px;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0px;
}

.mat-mdc-cell {
  border-bottom-width: 0px;
  border-color: white;
}

.rooms-detail-email-label {
  padding-top: 0.7em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

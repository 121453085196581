
// Angular
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// 3rdparty
import dayjs from 'dayjs'
// Hostware

import SessionStorage from '@app/helpers/SessionStorage';
import { HeaderService } from '@app/services/header.service';
import { BottomBarService } from '@app/services/bottom-bar.service';
import { YesNoDialogComponent } from '@app/dialogs/yes-no-dialog/yes-no-dialog.component';
import LicenceOptions from '@app/helpers/Options';
import { GuestStayStatus } from '@app/types/Types';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { IpAddressService } from '@app/services/ip-address-service';
import { SwUpdate } from '@angular/service-worker';
import { GenericMessageDialogComponent } from '@app/dialogs/generic-message-dialog/generic-message-dialog.component';
import { GenericMessage } from '@app/interfaces/generic-message';
import { Platform } from '@angular/cdk/platform';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  checkInDate: string;
  checkOutDate: string;
  hasExiLicence = LicenceOptions.HasExiLicence
  hasExoLicence = LicenceOptions.HasExoLicence
  destroy$: Subject<boolean> = new Subject<boolean>();
  modalPwaEvent: any;
  modalPwaPlatform: string|undefined;


  constructor(
    private router: Router,
    private headerService: HeaderService,
    private bottomBarService: BottomBarService,
    private translate: TranslateService,
    private ipService: IpAddressService,
    private dialog: MatDialog,
    private swUpdate : SwUpdate,
    private platform : Platform
  

  ) { }
  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    const lngCode = SessionStorage.ActiveLanguage
    this.translate.setDefaultLang(lngCode)
    this.translate.use(lngCode)


    this.checkInDate = dayjs(SessionStorage.ReservationInfo.Rooms[0].Guests[0].Arrival).locale(SessionStorage.ActiveLanguage).format('ll');
    this.checkOutDate = dayjs(SessionStorage.ReservationInfo.Rooms[0].Guests[0].Departure).locale(SessionStorage.ActiveLanguage).format('ll');
    this.headerService.setNavigationVisibility(true);

   
    // this.loadModalPwa();
    
    // this.swUpdate.versionUpdates.subscribe(evt => {

    //   switch (evt.type) {
    //     case 'VERSION_DETECTED':
    //       console.log(`Downloading new app version: ${evt.version.hash}`);
    //       break;
    //     case 'VERSION_READY':
    //       console.log(`Current app version: ${evt.currentVersion.hash}`);
    //       console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
    //       document.location.reload();
           
    //       break;
    //     case 'VERSION_INSTALLATION_FAILED':
    //       let messageData: GenericMessage = {messageText:this.translate.instant('common.new_software_version_error'), messageType: 'ERROR'}
    //       const dialogRef = this.dialog.open(GenericMessageDialogComponent, {
    //         disableClose: true,
    //         hasBackdrop: true,
    //         width: "85vw",
    //         data: messageData
    //       })

    //       console.error(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
    //       break;
    //   }
    // })

  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  checkInClick() {
    this.bottomBarService.setBottomBarVisibility(false);
    this.router.navigateByUrl('/checkinstatus')
  }

  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  guestName() {
    return SessionStorage.ReservationInfo.Rooms[0].Guests[0].Name;
  }

  private loadModalPwa(): void {
    if (this.platform.ANDROID ) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.modalPwaEvent = event;
        this.modalPwaPlatform = 'ANDROID';
      });
    }
    if (this.platform.isBrowser ) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.modalPwaEvent = event;
        this.modalPwaPlatform = 'BROWSER';
      });
    }

    this.addToHomeScreen();
  }

  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
  }

  //#endregion =================================================================================================================

  guests() {
    return SessionStorage.guestNumber();
  }

  nights() {
    return SessionStorage.nights();
  }


}
/*
  payment gomb az alábbi esetben lehet aktív:

  1. Legalább 1 fő (a szobafőnök) az alábbi státuszok valamelyikével rendelkezik: VIZA_VALID, READY_TO_CHECKIN, CHECKED_IN
  2. Csak az érkezés napján lehet aktív
  3. Van a szobaszámlán terhelése
*/
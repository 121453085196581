import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subject, Subscription, combineLatest, takeUntil } from 'rxjs';
// Hostware
import { HttpErrorResponse } from '@angular/common/http';
import { PayStartRq } from '@app/dto/PayStartRequest';
import { PayStartResponse } from '@app/dto/PayStartResponse';
import { RoomGuest } from '@app/dto/ReservationInfo';
import SessionStorage from '@app/helpers/SessionStorage';
import { Balance } from '@app/interfaces/Balance';
import { Charge, StartBillingResponse } from '@app/interfaces/StartBillingResponse';
import { GenericMessage } from '@app/interfaces/generic-message';
import { BillingService } from '@app/services/billing.service';
import { SpinnerService } from '@app/services/spinner.service';
import { BalanceType } from '@app/types/Types';
import dayjs from 'dayjs';
import { CurrencySettings } from '../../../../../froexishared/src/dto/CurrencySettingsDto';
import { SubscriberParameter } from '../../../../../froexishared/src/dto/SubscriberParameter';
import { SubscriberParameterService } from '../../../../../froexishared/src/services/SubscriberParameter.service';
import { GenericMessageDialogComponent } from '../../dialogs/generic-message-dialog/generic-message-dialog.component';
import { SimpleVerificationDialogComponent } from '@app/dialogs/simple-verification-dialog/simple-verification-dialog.component';
import { YesNoDialogComponent } from '@app/dialogs/yes-no-dialog/yes-no-dialog.component';
import { BigFishPayMode } from '@app/interfaces/BigFishPayMode';
import { BigfishPaymentDialogComponent } from '@app/dialogs/bigfish-payment-dialog/bigfish-payment-dialog.component';
import { BigFish } from '@app/interfaces/BigFish';

@AutoUnsubscribe()
@Component({
  selector: 'bill-view',
  templateUrl: './bill-view.component.html',
  styleUrls: ['./bill-view.component.scss']
})
export class BillViewComponent implements OnInit, OnDestroy {

  loadBalance$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  startBillingRespose: StartBillingResponse;
  dataSource: MatTableDataSource<Balance>;
  selectedBalanceType: BalanceType;
  selection = new SelectionModel<Balance>(true, []);
  queryParams: Params;
  showDeposit: boolean = false;
  loaded: boolean = false;
  currencySettings: CurrencySettings;
  total: number = 0

  sumDeposit: number;
  paymentParameters: any;
  allowedPaymentType: string
  stripeParameters: StripeParameters;
  simpleParameters: SimpleParameters;
  bigFishPaymentParameters: BigFish;
  paymentProvider: string
  summarizedCharges: ChargeSummarizer[] = [];
  excludedServices: string[] = [];
  sumExcluded: number = 0;


  constructor(
    private router: Router,
    private translate: TranslateService,
    private subscriberParamaterService: SubscriberParameterService,
    private activatedRoute: ActivatedRoute,
    private billingService: BillingService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
  ) { }



  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.showDeposit = true //this.queryParams["showdeposit"] == "true" ? true : false;

      const selectedGuest = JSON.parse(sessionStorage.getItem('sgd')) as RoomGuest
      // alert('false vissza')
      this.queryParams = params;
      this.selectedBalanceType = 'OUTSTANDING';
      this.spinnerService.setSpinnerVisibility(true);
      const combinedSources = combineLatest({
        startbillingResponse: this.billingService.StartBilling(selectedGuest.GuestStayUuId, SessionStorage.ActiveLanguage, false),
        currencySettings: this.subscriberParamaterService.loadByTypeCode(SessionStorage.ReservationInfo.Subscriber,
          parseInt(SessionStorage.ReservationInfo.Hotelid), "CURRENCY_SETTINGS"),
        paymentParameters: this.subscriberParamaterService.loadByTypeCode(SessionStorage.ReservationInfo.Subscriber,
          parseInt(SessionStorage.ReservationInfo.Hotelid), "PAYMENT_PARAMETERS"),
        paymentTypes: this.subscriberParamaterService.loadByTypeCode(SessionStorage.ReservationInfo.Subscriber,
          parseInt(SessionStorage.ReservationInfo.Hotelid), "PAYMENT_TYPE"),
        excludeds: this.subscriberParamaterService.loadByTypeCode(SessionStorage.ReservationInfo.Subscriber,
          parseInt(SessionStorage.ReservationInfo.Hotelid), "EXCLUDED_SERVICES")

      }).pipe(takeUntil(this.destroy$)).subscribe({
        next: (values) => {
          this.startBillingRespose = values.startbillingResponse as StartBillingResponse;


          let subsparam = values.currencySettings as SubscriberParameter;
          const paymentParam = values.paymentParameters as SubscriberParameter;
          this.paymentParameters = JSON.parse(paymentParam.Param_Json_Value)

          const paymentType = values.paymentTypes as SubscriberParameter;
          this.allowedPaymentType = paymentType.Param_String_Value

          this.excludedServices = (values.excludeds as SubscriberParameter).Param_String_Value.split(",")
          switch (this.allowedPaymentType) {
            case "SIMPLE":
              this.processSimplePayment();
              break;
            case "BIGFISH":
              this.bigFishPaymentParameters = JSON.parse(paymentParam.Param_Json_Value)["BIGFISH"] as BigFish
              this.processBigFishPayment();
              break;
          }
          this.currencySettings = JSON.parse(subsparam.Param_Json_Value) as CurrencySettings;

          // calculate total

          this.startBillingRespose.Transaction.RoomStayCharges.forEach(charge => {
            charge.RoomStayCharges.forEach(roomCharge => {
              if(!this.canBePayed(roomCharge.ServiceId))  {
                this.sumExcluded = this.sumExcluded + (roomCharge.Amount )
              }
              if (this.summarizedCharges.length === 0 && this.canBePayed(roomCharge.ServiceId)) {
                this.summarizedCharges.push({
                  Amount: roomCharge.Amount,
                  ServiceId: roomCharge.ServiceId,
                  ServiceIdCounter: roomCharge.Quantity,
                  Description: roomCharge.Description,
                  SumValue: roomCharge.Amount
                })
              } else {
                if (this.canBePayed(roomCharge.ServiceId)) {
                  const foundIndex = this.summarizedCharges.findIndex(item => item.ServiceId == roomCharge.ServiceId)
                  if (foundIndex == -1) {
                    this.summarizedCharges.push({
                      Amount: roomCharge.Amount,
                      ServiceId: roomCharge.ServiceId,
                      ServiceIdCounter: roomCharge.Quantity,
                      Description: roomCharge.Description,
                      SumValue: roomCharge.Amount
                    })
                  } else {
                    this.summarizedCharges[foundIndex].ServiceIdCounter += roomCharge.Quantity;
                    this.summarizedCharges[foundIndex].SumValue +=  roomCharge.Amount;
                  }
                }

              }
            })
          })

          this.startBillingRespose.Transaction.RoomStayCharges.forEach(charge => {
            charge.GuestStayCharges.forEach(guestCharge => {
              guestCharge.GuestCharges.forEach(guestItemCharge => {
                if(!this.canBePayed(guestItemCharge.ServiceId))  {
                  this.sumExcluded = this.sumExcluded + (guestItemCharge.Amount )
                }

                if (this.canBePayed(guestItemCharge.ServiceId)) {
                  this.summarizedCharges.push({
                    Amount: guestItemCharge.Amount,
                    ServiceId: guestItemCharge.ServiceId,
                    ServiceIdCounter: guestItemCharge.Quantity,
                    Description: guestItemCharge.Description,
                    SumValue: guestItemCharge.Amount
                  })
                } else {
                  if (!this.excludedServices.find(item => item == guestItemCharge.ServiceId)) {
                    const foundIndex = this.summarizedCharges.findIndex(item => item.ServiceId === guestItemCharge.ServiceId)
                    if (foundIndex == -1) {
                      this.summarizedCharges.push({
                        Amount: guestItemCharge.Amount,
                        ServiceId: guestItemCharge.ServiceId,
                        ServiceIdCounter: guestItemCharge.Quantity,
                        Description: guestItemCharge.Description,
                        SumValue: guestItemCharge.Amount
                      })
                    } else {
                      this.summarizedCharges[foundIndex].ServiceIdCounter += guestItemCharge.Quantity;
                      this.summarizedCharges[foundIndex].SumValue +=  guestItemCharge.Amount;
                    }
                  }
                }
              })
            })
          })


          let percent0Total = this.startBillingRespose.PayValues["0"]?.ToPay ? this.startBillingRespose.PayValues["0"].ToPay : 0
          let percent5Total = this.startBillingRespose.PayValues["5"]?.ToPay ? this.startBillingRespose.PayValues["5"].ToPay : 0
          let percent27Total = this.startBillingRespose.PayValues["27"]?.ToPay ? this.startBillingRespose.PayValues["27"].ToPay : 0

          let percent0Deposit = this.startBillingRespose.PayValues["0"]?.Deposit ? this.startBillingRespose.PayValues["0"].Deposit : 0
          let percent5Deposit = this.startBillingRespose.PayValues["5"]?.Deposit ? this.startBillingRespose.PayValues["5"].Deposit : 0
          let percent27Deposit = this.startBillingRespose.PayValues["27"]?.Deposit ? this.startBillingRespose.PayValues["27"].Deposit : 0

          this.total = percent0Total - this.sumExcluded + percent5Total + percent27Total - (percent0Deposit + percent5Deposit + percent27Deposit)
          this.sumDeposit = percent0Deposit + percent5Deposit + percent27Deposit;
          this.loaded = true
          this.spinnerService.setSpinnerVisibility(false);
        },
        error: err => {
          this.spinnerService.setSpinnerVisibility(false);
          let messageData: GenericMessage = { messageText: this.translate.instant('common.network_error'), messageType: 'ERROR' }
          const dialogRef = this.dialog.open(GenericMessageDialogComponent, {
            disableClose: true,
            hasBackdrop: true,
            minWidth: "95vw",
            data: messageData
          })
        }
      })
    })


  }
  ngOnDestroy(): void {
    this.loadBalance$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  payClick() {
    // alert("Kivenni")
    // this.allowedPaymentType = "SIMPLE"
    // create payment  structure
   
      switch (this.allowedPaymentType) {
        case "SIMPLE":
          const dialog =   this.dialog.open(SimpleVerificationDialogComponent, {
            id: 'simpleverification',
            disableClose: true,
            minWidth: "100vw",
            minHeight: "60vh"
          })
          dialog.afterClosed().subscribe(data => {
            if (data.result === 'acceptedPolicy') this.startPay(SessionStorage.UseDeposit == 'y' ? true: false, "SIMPLE");
          })
          break;
        case "BIGFISH":
          this.startPay( SessionStorage.UseDeposit == 'y' ? true: false, SessionStorage.SelectedPaymentMode)
          break;
      }
  
  }

  startPay(isDepositUsing: boolean, paymentType: string) {
    const invDetails = SessionStorage.InvoiceDetails;


    let payStartRequest: PayStartRq = {
      HwTransactionId: this.startBillingRespose.Transaction.TransactionId,
      PaymentType: paymentType,
      IsDepositUsing: isDepositUsing,
      VatOrigin: SessionStorage.InvoiceDetails.TaxableEntity, //  "M",
      Address: invDetails.Address,
      City: invDetails.City,
      Company: "",
      Country: invDetails.Country,
      CustomerEmail: invDetails.Email,
      FirstName: invDetails.FirstName,
      LastName: invDetails.LastName,
      Phone: "+" + invDetails.PhonePrefix +  invDetails.Phone,
      State: "",
      TaxNumber: invDetails.TaxNo,
      Zip: invDetails.Zip,
      HotelId: SessionStorage.GuestData.HotelId,
      Subscriber: SessionStorage.GuestData.Subscriber,
      Currency: this.currencySettings.CurrencyCode,
      GuestIp : SessionStorage.IpAddress
    }
    this.billingService.StartPayment(payStartRequest).subscribe({
      next: (startPayResult) => {
        let resp = startPayResult as PayStartResponse
        if (resp.ErrorCodes !== null && resp.ErrorCodes !== undefined) {
          let messageContent = this.translate.instant("payment." + resp.ErrorCodes[0])
          let messageData: GenericMessage = { messageText: messageContent, messageType: 'ERROR' }
          const dialogRef = this.dialog.open(GenericMessageDialogComponent, {
            disableClose: true,
            hasBackdrop: true,
            width: "85vw",
            data: messageData
          })
        } else {
          //this.router.navigateByUrl(resp.paymentUrl);
          this.router.navigate(['/externalRedirect', { externalUrl: resp.PaymentUrl }]);

        }
      },
      error: (err: HttpErrorResponse) => {
        let messageData: GenericMessage = { messageText: this.translate.instant('common.network_error'), messageType: 'ERROR' }
        const dialogRef = this.dialog.open(GenericMessageDialogComponent, {
          disableClose: true,
          hasBackdrop: true,
          minWidth: "95vw",
          data: messageData
        })
        this.dialog.getDialogById('simpleverification').close()
      },
    })

  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  formattedDate(dateString: string): string {
    return dayjs(dateString).locale(SessionStorage.ActiveLanguage).format('ll');
  }

  processSimplePayment() {
    this.simpleParameters = {
      CancelUrl: this.paymentParameters["SIMPLE"]["CancelUrl"],
      ClientUrl: this.paymentParameters["SIMPLE"]["ClientUrl"],
      FailUrl: this.paymentParameters["SIMPLE"]["FailUrl"],
      Merchant: this.paymentParameters["SIMPLE"]["Merchant"],
      MerchantKey: this.paymentParameters["SIMPLE"]["MerchantKey"],
      SuccessUrl: this.paymentParameters["SIMPLE"]["SuccessUrl"],
      TimeOutUrl: this.paymentParameters["SIMPLE"]["TimeOutUrl"]
    }

  }

  logoPath(): string {
    switch (this.allowedPaymentType) {
      case "SIMPLE":
        return "assets/logos/simplepay_w140.png"
      case "BIGFISH":
        return "assets/logos/bigfish.png"
    }
  }

  processBigFishPayment() {

  }

  canBePayed(serviceId : string) : boolean  {
    let ret: boolean = true;
    switch(SessionStorage.SelectedPaymentType) {
      case "CREDITCARD": 
        return true
      case "SZEPCARD": 
        return !this.excludedServices.find(item => item == serviceId)
    }
  }


  // balanceItemStyle(rst: RoomStayCharge) {
  //   let styles = {};
  //   styles["color"] = rst.Price > 0 ? 'black' : 'red'
  //   return styles;
  // }



  //#endregion =================================================================================================================

}

interface ChargeSummarizer {
  ServiceId: string;
  SumValue: number
  ServiceIdCounter: number;
  Description: string;
  Amount: number;

}

interface StripeParameters {
  CancelUrl: string;
  ClientUrl: string;
  FailUrl: string;
  Merchant: string;
  MerchantKey: string;
  SuccessUrl: string;
  TimeOutUrl: string;
}

interface SimpleParameters {
  FailUrl: string,
  Merchant: string,
  CancelUrl: string,
  ClientUrl: string,
  SuccessUrl: string,
  TimeOutUrl: string,
  MerchantKey: string
}
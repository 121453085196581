// Angular
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { InvoiceDetailsFormData } from "@app/dto/InvoiceDetailsFormData";
import ChatVisibilityHelper from "@app/helpers/ChatvisibilityHelper";
import SessionStorage from "@app/helpers/SessionStorage";
import { Country } from "@app/interfaces/Country";
import { LabelValue } from "@app/interfaces/LabelValue";
import { ZiplCodes } from "@app/interfaces/ZipCodes";
import { LoadCsvService } from "@app/services/loadCsv.service";
// 3rdparty
import { TranslateService } from "@ngx-translate/core";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe";
import { Subject, Subscription, combineLatest, debounceTime, takeUntil } from "rxjs";
import { SubscriberParameterService } from "../../../../../froexishared/src/services/SubscriberParameter.service";
import { BillingService } from "@app/services/billing.service";
import { SpinnerService } from "@app/services/spinner.service";
import { RoomGuest } from "@app/dto/ReservationInfo";
import { StartBillingResponse } from "@app/interfaces/StartBillingResponse";
import { SubscriberParameter } from "../../../../../froexishared/src/dto/SubscriberParameter";
import { BigFish } from "@app/interfaces/BigFish";
import { GenericMessage } from "@app/interfaces/generic-message";
import { GenericMessageDialogComponent } from "@app/dialogs/generic-message-dialog/generic-message-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { BigfishPaymentDialogComponent } from "@app/dialogs/bigfish-payment-dialog/bigfish-payment-dialog.component";
import { ExcludedServicesInformationDialogComponent } from "@app/dialogs/excluded-services-information-dialog/excluded-services-information-dialog.component";
// Hostware

@Component({
  selector: "invoice-details",
  templateUrl: "./invoice-details.component.html",
  styleUrls: ["./invoice-details.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
@AutoUnsubscribe()
export class InvoiceDetailsComponent implements OnInit, OnDestroy {
  group: FormGroup;
  countries: Country[] = [];
  zipCodes: ZiplCodes[] = [];
  taxableTypes: LabelValue[] = [];
  loadCountries$: Subscription;
  filteredCountry;
  destroy$: Subject<boolean> = new Subject<boolean>();
  startBillingRespose: StartBillingResponse;
  paymentParameters: any;
  allowedPaymentType: string;
  bigFishPaymentParameters: BigFish;
  loaded: boolean

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private loadCsvService: LoadCsvService,
    private subscriberParamaterService: SubscriberParameterService,
    private activatedRoute: ActivatedRoute,
    private billingService: BillingService,
    private spinnerService: SpinnerService,
    private errorDialog: MatDialog,
    private bigFishPaymentDialog: MatDialog,
    private ref: ChangeDetectorRef,
    private szepCardAlertDialog: MatDialog

  ) {
    this.group = this.fb.group({
      Name: new FormControl("", [Validators.required]),
      Address: new FormControl("", [Validators.required]),
      Zip: new FormControl("", [Validators.required]),
      City: new FormControl("", [Validators.required]),
      Country: new FormControl("", [Validators.required]), // set default based on MRZ data
      Email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
      ]),
      TaxableEntity: new FormControl("M", [Validators.required]),
      TaxNo: new FormControl(""),
    });
  }

  // startbilling, onlyview = false
  ngOnInit() {
    this.translate.getDefaultLang() === 'hu' ? this.loadCsvService.getDataFromCsv('assets/data/hu-countries.csv', ";", this.countries, 'lang') : this.loadCsvService.getDataFromCsv('assets/data/en-countries.csv', ";", this.countries, 'lang');
    this.filteredCountry = this.countries;

    this.loadCsvService.getDataFromCsv('assets/data/zip-codes.csv', ";", this.zipCodes, 'zipCode');

    this.taxableTypes = [
      {
        Label: this.translate.instant("invoice_details.person"),
        Value: "M",
      },
      {
        Label: this.translate.instant("invoice_details.lpwtaxnum"),
        Value: "J",
      },
      {
        Label: this.translate.instant("invoice_details.domestic_taxpayer"),
        Value: "B",
      },
      {
        Label: this.translate.instant("invoice_details.community_taxpayer"),
        Value: "K",
      },
      {
        Label: this.translate.instant("invoice_details.third_country_taxpayer"),
        Value: "H",
      }
    ];

    combineLatest({
      countryCode: this.group.get('Country').valueChanges,
      postalCode: this.group.get('Zip').valueChanges
    }).pipe(debounceTime(300))
      .subscribe((result) => {
        if (result.countryCode === 'HU') {
          let filterCity = this.zipCodes.find(zipCode => zipCode.zipCode === result.postalCode).City
          this.group.get('City').setValue(filterCity)
        }
      })
    this.preparePayment()
  }

  onKeyCountry(event) {
    this.filteredCountry = this.search(event.target.value);
  }

  search(value: string) {
    let filter = value.toLowerCase()
    return this.countries.filter(country => country.Name.toLocaleLowerCase().includes(filter));
  }

  navigateBack() {
    this.router.navigateByUrl("/balance");
  }

  prepareTaxNoField(vatOrigin: string) {
    this.group.controls["TaxNo"].clearValidators();
    switch (vatOrigin) {
      case VAT_ORIGINS.BELFOLDI_ADOAALANY:
        this.group.controls["TaxNo"].setValidators([Validators.required, adoszamValidator()])
        this.group.controls["TaxNo"].updateValueAndValidity()
        break;
      case VAT_ORIGINS.KOZOSSEGI_ADOALANY:
      case VAT_ORIGINS.HARMADIK_ORSZAGBELI_ADOALANY:
        break;
    }
    this.group.updateValueAndValidity()
  }

  taxNoFieldVisibleStyle() {
    let styles = {};
    if (this.group.controls["TaxableEntity"].value == "M" || this.group.controls["TaxableEntity"].value == "J") {
      styles["visibility"] = "hidden"
      styles["position"] = "absolute"
    } else {
      styles["visibility"] = "visible"
    }
    return styles
  }
  preparePayment() {
    // store local data
    let formData = this.group.value;
    let originalFormData = formData as InvoiceDetailsFormData;
    //originalFormData.Country = this.countries.find(item=>item.Id === (formData as InvoiceDetailsFormData).Country).ShortName
    // országkód csere 2 karakteresre
    SessionStorage.InvoiceDetails = originalFormData
    const selectedGuest = JSON.parse(sessionStorage.getItem('sgd')) as RoomGuest
    const combinedSources = combineLatest({
      startbillingResponse: this.billingService.StartBilling(selectedGuest.GuestStayUuId, SessionStorage.ActiveLanguage, true),
      allParameters: this.subscriberParamaterService.loadAll(SessionStorage.ReservationInfo.Subscriber,
        parseInt(SessionStorage.ReservationInfo.Hotelid)),
    }).pipe(takeUntil(this.destroy$)).subscribe({
      next: (values) => {
        this.startBillingRespose = values.startbillingResponse as StartBillingResponse;

        const paymentParam = (values.allParameters as SubscriberParameter[]).find(item => item.Param_Typecode == "PAYMENT_PARAMETERS");
        this.paymentParameters = JSON.parse(paymentParam.Param_Json_Value)

        const paymentType = (values.allParameters as SubscriberParameter[]).find(item => item.Param_Typecode == "PAYMENT_TYPE");
        this.allowedPaymentType = paymentType.Param_String_Value


        switch (this.allowedPaymentType) {
          case "SIMPLE":
            //this.processSimplePayment();
            break;
          case "BIGFISH":
            this.bigFishPaymentParameters = JSON.parse(paymentParam.Param_Json_Value)["BIGFISH"] as BigFish
            break;
        }

        this.loaded = true
        this.ref.detectChanges();
        this.spinnerService.setSpinnerVisibility(false);
      },
      error: err => {
        this.spinnerService.setSpinnerVisibility(false);
        let messageData: GenericMessage = { messageText: this.translate.instant('common.network_error'), messageType: 'ERROR' }
        const dialogRef = this.errorDialog.open(GenericMessageDialogComponent, {
          disableClose: true,
          hasBackdrop: true,
          width: "85vw",
          data: messageData
        })
      }
    })
  }


  ngOnDestroy(): void {
    this.loadCountries$?.unsubscribe();
  }

  chatIconStyle() {
    let styles = {};
    styles = ChatVisibilityHelper.ChatVisibleStyle
    return styles
  }

  forward() {
    let formData = this.group.value;
    let originalFormData = formData as InvoiceDetailsFormData;
    if (SessionStorage.SelectedPaymentType == "SZEPCARD") {
      if (SessionStorage.HasExludedService === 'y') {
        this.szepCardAlertDialog.open(ExcludedServicesInformationDialogComponent).afterClosed().subscribe(result => {
          const isContinue = result ? true : false
          if (isContinue) {
            SessionStorage.InvoiceDetails = originalFormData
            this.router.navigateByUrl('/billview')
          } else {
            this.router.navigateByUrl('/welcome')
          }
        })
      } else {
        SessionStorage.InvoiceDetails = originalFormData
        this.router.navigateByUrl('/billview')
      }
    } else {
      SessionStorage.InvoiceDetails = originalFormData
      this.router.navigateByUrl('/billview')
    }
  }
}

export function adoszamValidator(): ValidatorFn {
  //10426917-2-42 
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value.toString() === '') {
      return { invalidAdoszam: true };
    }
    var pattern = /^(\d{7})(\d)\-([1-5])\-(0[2-9]|[13][0-9]|2[02-9]|4[0-4]|51)$/;
    var matches = control.value.match(pattern);
    if (matches) {
      // Szorzók az adószám törzsszám része (első 8 jegy) ellenőrző számjegyének
      // meghatározásához (a 8. jegy az első hét jegy alapján). Az adott szorzóval, a
      // törzsszám megfelelő számjegyét (elölről indulva) megszorozzuk, majd ezen
      // szorzatok összegét képezzük.
      var mul = [9, 7, 3, 1, 9, 7, 3];
      // Az adószám törzsszám részének első 7 jegye.
      var base = matches[1].split("");
      // Ellenőrző számjegy (az adószám 8. jegye).
      var check = parseInt(matches[2]);
      // Ellenőrző összeg meghatározása.
      var sum = 0;
      for (var i = 0; i < 7; i++) { sum += parseInt(base[i]) * mul[i]; }
      // Az ellenőrző összeg utolsó jegyének meghatározása (= 10-zel való osztás maradéka).
      var last = sum % 10;
      // Ha a kiszámított ellenőrző számjegy nem nulla, akkor 10-ből kivonjuk.
      if (last > 0) { last = 10 - last; }
      // A kiszámított és kapott ellenőrző számjegyek egyezősége alapján
      // lesz helyes vagy helytelen az adószám.
      if (last === check) {
        return null;
      } else {
        return { invalidAdoszam: true };
      }

    } else {
      return { invalidAdoszam: true };
    }
  }
}


enum VAT_ORIGINS {
  "MAGANSZEMELY" = "M",
  "ADOSZAM_NELKULI_JOGI_SZEMELY" = "J",
  "BELFOLDI_ADOAALANY" = "B",
  "KOZOSSEGI_ADOALANY" = "K",
  "HARMADIK_ORSZAGBELI_ADOALANY" = "H"
}

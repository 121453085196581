// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
#customer-facing mat-card {
  margin: 0;
  padding: 0;
  box-shadow: none;
}
#customer-facing mat-card .FROexi-chat-content {
  justify-content: space-between;
}
#customer-facing mat-card mat-card-content {
  padding: 0;
  height: calc(100dvh - 7rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#customer-facing mat-card mat-card-content .user-information {
  display: flex;
  justify-content: space-between;
  background-color: rgba(192, 192, 192, 0.2705882353);
  padding: 0.5em 0.5em 0.5em 0.5em;
  border-radius: 5px;
}
#customer-facing mat-card mat-card-content .froexi-chat-design {
  display: flex;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 0.75rem;
  padding-bottom: 1.25rem;
  flex-direction: column;
  justify-content: flex-start;
  height: 80%;
  background-color: #F3F4F6;
}

#backoffice mat-card mat-card-content {
  display: flex;
  gap: 1rem;
}
#backoffice mat-card mat-card-content aside {
  width: 21rem;
}
#backoffice mat-card mat-card-content .backoffice__chat {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100dvh - 7.5rem);
  border-width: 2px;
  border-left-style: solid;
  border-color: #eeeef8;
  padding: 1em;
}
#backoffice mat-card mat-card-content .backoffice__chat .message_design {
  height: inherit;
  overflow-y: auto;
}

#FROhok mat-card {
  padding: 1rem;
}
#FROhok mat-card mat-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100dvh - 10rem);
}

.chat-message {
  display: flex;
  flex-direction: column;
}
.chat-message .left-message {
  display: flex;
  align-items: flex-end;
}
.chat-message .left-message .left-message-tooltip {
  display: flex;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  flex-direction: column;
  order: 2;
  align-items: flex-start;
  max-width: 20rem;
}
.chat-message .left-message .left-message-design {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5em;
  border-bottom-left-radius: 0;
  width: 100%;
  color: #4B5563;
  overflow-wrap: break-word;
  background-color: #D1D5DB;
}
.chat-message .right-message {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.chat-message .right-message .right-message-toolip {
  display: flex;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  flex-direction: column;
  order: 1;
  align-items: flex-end;
  max-width: 20rem;
}
.chat-message .right-message .right-message-design {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5em;
  border-bottom-right-radius: 0;
  width: 100%;
  color: #ffffff;
  overflow-wrap: break-word;
  background-color: #2563EB;
}

.message {
  position: relative;
  display: flex;
}

.message-input-wrapper {
  position: relative;
  flex-grow: 1;
  gap: 1rem;
  margin: 0.5rem;
  border-radius: 5px;
}
.message-input-wrapper .message-input-buttons {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  top: 0;
  bottom: 0;
}
.message-input-wrapper input {
  border-radius: 0.375rem;
  background-color: rgb(229, 231, 235);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  color: rgb(75, 85, 99);
  width: 100%;
}

.button-for-message {
  margin: auto;
  background-color: #41c300;
  border-color: #41c300 !important;
  color: white;
  height: 48px;
  width: 64px;
  border-radius: 5px;
  font-size: 25px;
}

.button-for-message:hover {
  background-color: #3bb300;
  border-color: #3bb300 !important;
}

aside {
  height: calc(100dvh - 7.6rem);
  overflow-y: auto;
}

.aside-title {
  font-size: 20px;
  border: 2px solid #0e0d21;
  font-weight: 500;
  margin-top: auto;
  border-width: 2px;
  max-width: 300px;
  min-width: 300px;
  padding: 0.5em 0em 0.5em 0em;
  margin-bottom: 0.7em;
  border-radius: 15px;
  text-align: center;
}

.chat-rooms {
  background-color: white;
  border-style: solid;
  border-color: #eeeef8;
  border-width: 2px;
  min-width: 300px;
  padding: 0.5em 1em 0.5em 1em;
  margin-bottom: 1em;
  border-radius: 15px;
  margin-right: 1em;
}

.active-chat-rooms {
  background-color: #eeeef8;
  border-width: 2px;
  min-width: 300px;
  padding: 0.5em 1em 0.5em 1em;
  margin-bottom: 1em;
  border-radius: 15px;
}

.chat-rooms-title {
  color: black;
  font-size: 16px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
}

.chat-rooms-icon {
  color: #2563eb;
}

.mat-mdc-chat-start {
  background-color: #ffffff;
  color: #565656;
  border-style: solid !important;
  border-color: #565656 !important;
  border-width: 2px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  padding: 0 16px !important;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
}

.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.grey-button {
  background-color: grey;
  color: white;
}

.user-name {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 0.5em;
}

.hidden-radio {
  display: none;
}

.active-chat-rooms {
  background-color: #e5e7eb;
  cursor: pointer;
}

.hidden-radio:checked + .active-chat-rooms {
  background-color: #2563eb;
}

.hidden-radio:checked + .active-chat-rooms .chat-rooms-title {
  color: white;
  /* Aktív állapotban a kívánt szín */
}

.hidden-radio:checked + .active-chat-rooms .chat-rooms-icon {
  color: white;
  /* Aktív állapotban a kívánt szín */
}

.active-chat-rooms:hover {
  background-color: #f0f0f0;
  /* Hover szín */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

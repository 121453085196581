// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-selection-wrapper {
  top: 50%;
  left: 50%;
}

.title {
  font-size: 21px;
  font-weight: 900;
}

.language-selection-image {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border-radius: 0 0 30px 30px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

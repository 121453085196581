import { Directive, Input, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[alphabeticOnly]"
})
export class AlphabeticOnlyDirective {
constructor(private el: ElementRef) {}

private regex: RegExp = new RegExp(/^[a-zA-Z'-\s]*$/);

@HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!this.regex.test(input.value)) {
      input.value = input.value.replace(/[^a-zA-Z'-\s]/g, '');
    }
  }
}



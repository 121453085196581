import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BigfishPaymentDialogComponent, BigFishWrapper } from '@app/dialogs/bigfish-payment-dialog/bigfish-payment-dialog.component';
import { ChatDialogComponent } from '@app/dialogs/chat-dialog/chat-dialog.component';
import { ExcludedServicesInformationDialogComponent } from '@app/dialogs/excluded-services-information-dialog/excluded-services-information-dialog.component';
import { GenericMessageDialogComponent } from '@app/dialogs/generic-message-dialog/generic-message-dialog.component';
import { SendInvitationDialogComponent } from '@app/dialogs/send-invitation-dialog/send-invitation-dialog.component';
import { SimpleVerificationDialogComponent } from '@app/dialogs/simple-verification-dialog/simple-verification-dialog.component';
import { YesNoDialogComponent } from '@app/dialogs/yes-no-dialog/yes-no-dialog.component';
import { ReservationInfo, Room, RoomGuest } from '@app/dto/ReservationInfo';
import ChatVisibilityHelper from '@app/helpers/ChatvisibilityHelper';
import LicenceOptions from '@app/helpers/Options';
import SessionStorage from '@app/helpers/SessionStorage';
import { BigFish } from '@app/interfaces/BigFish';
import { BigFishPayMode } from '@app/interfaces/BigFishPayMode';
import { GenericMessage } from '@app/interfaces/generic-message';
import { BottomBarService } from '@app/services/bottom-bar.service';
import { InvitationEmailService } from '@app/services/invitation-email.service';
import { ReservationService } from '@app/services/reservation.service';
import { GuestStayStatus } from '@app/types/Types';
import { ChatService } from '@globalshared/chat/src/lib/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { Socket } from 'ngx-socket-io';
@Component({
  selector: 'app-check-in-status',
  templateUrl: './check-in-status.component.html',
  styleUrls: ['./check-in-status.component.scss']
})
export class CheckInStatusComponent implements OnInit {
  hasExiLicence = LicenceOptions.HasExiLicence
  hasExoLicence = LicenceOptions.HasExoLicence
  rooms: Room[] = SessionStorage.ReservationInfo.Rooms
  completionCounter: string = '';
  public guestStayStatusType: GuestStayStatus;
  contentVisible: boolean = true;

  messageCounterAfterClose: number = 0
  messageCounter: number = 0
  badgeValue: number = 0

  constructor(
    private router: Router,
    private bottomBarService: BottomBarService,
    private dialog: MatDialog,
    private genMessageDialog: MatDialog,
    private translate: TranslateService,
    private invitationsService: InvitationEmailService,
    private reservationService: ReservationService,
    private chatService: ChatService

  ) { }

  ngOnInit() {
    this.bottomBarService.setBottomBarVisibility(true);
    this.completionCounter = this.calculateCompletion();
    this.refreshReservationInfo();

    this.chatService.customerFacingDataLength.subscribe(data => {
      this.messageCounter = data;

      if (this.messageCounter > this.messageCounterAfterClose) {
        this.badgeValue = this.messageCounter - this.messageCounterAfterClose
      } else {
        this.badgeValue = 0
      }
    })
  }

  openChat() {
    let reOpenChat = sessionStorage.getItem('reOpenChat');
    if (reOpenChat === 'true') {
      this.dialog.open(ChatDialogComponent, {
        minWidth: '90dvw',
        height: '95dvh',
        data: {
          isMinimized: true
        }
      }).afterClosed().subscribe(data => {
        this.badgeValue = 0
        this.messageCounterAfterClose = data
      })
    } else {
      this.dialog.open(YesNoDialogComponent, {
        disableClose: true,
        hasBackdrop: true,
        width: "85vw",
        data: { messageText: this.translate.instant('chat.start_chat_question'), messageType: 'INFORMATION' }
      }).afterClosed().subscribe(data => {
        if (data) {
          this.dialog.open(ChatDialogComponent, {
            minWidth: '90dvw',
            height: '95dvh',
            data: {
              isMinimized: false
            }
          }).afterClosed().subscribe(data => {
            this.badgeValue = 0
            this.messageCounterAfterClose = data
          })
        }
      })
    }
  }

  refreshReservationInfo(): void {
    let urlData = JSON.parse(localStorage.getItem('urlData'));
    this.reservationService.GetReservationInfo(urlData.guestStayUuid, urlData.invite).subscribe({
      next: (reservationResponse: ReservationInfo) => {
        this.rooms = reservationResponse.Rooms
      }
    });
  }

  checkInGuest(guest: RoomGuest) {
    // store selected guest data
    SessionStorage.SelectedGuestData = guest;
    SessionStorage.MrzData = undefined;
    SessionStorage.FaceImageFromCard = '';
    SessionStorage.Selfie = '';
    this.router.navigateByUrl('/showcheckinsteps')
  }

  mustShowCheckState(guest: RoomGuest) {
    if (guest.Status == GuestStayStatus.CHECKED_OUT || guest.Status == GuestStayStatus.CHECKED_IN || guest.Status == GuestStayStatus.READY_TO_CHECKIN || guest.Status == GuestStayStatus.VIZA_VALID) {
      return true
    } else {
      return false
    }
  }
  invite(guest: RoomGuest) {
    const dialogRef = this.dialog.open(SendInvitationDialogComponent,
      {
        disableClose: true,
        hasBackdrop: true,
        //minWidth: '95%',
        maxWidth: 'auto',
        data: guest
        // height: "350px"
      })
    dialogRef.afterClosed().subscribe(result => {
      let message: string;
      let messageData: GenericMessage;

      if (result) {
        // send invitation
        const res = result as RoomGuest;
        this.invitationsService.sendInvitation(res).subscribe({

          next: (result) => {
            message = this.translate.instant('send_invitation.email_sent').toString();
            messageData = { messageText: message, messageType: 'INFORMATION' }
            const okRef = this.genMessageDialog.open(GenericMessageDialogComponent, {
              disableClose: true,
              hasBackdrop: true,
              minWidth: "95vw",
              data: messageData
            })
          },
          error: (err: HttpErrorResponse) => {
            message = this.translate.instant('common.network_error').toString();
            messageData = { messageText: message, messageType: 'ERROR' }
            const okRef = this.genMessageDialog.open(GenericMessageDialogComponent, {
              disableClose: true,
              hasBackdrop: true,
              minWidth: "95vw",
              data: messageData
            })
          },
        })
      }
    })
  }



  paymentAllowed(guest: RoomGuest): boolean {
    if (!LicenceOptions.HasExoLicence)
      return true;
    if (guest.Status == GuestStayStatus.CHECKED_IN) {
      return true
    } else {
      return false
    }
    //  return guest.Status == GuestStayStatus.CHECKED_IN ? false: true
  }

  licenceOk(): boolean {
    if (LicenceOptions.HasExiLicence && !LicenceOptions.HasExoLicence) {
      return true
    }

    if (LicenceOptions.HasExiLicence && LicenceOptions.HasExoLicence) {
      return true
    }

    if (!LicenceOptions.HasExiLicence && LicenceOptions.HasExoLicence) {
      return false
    }


  }

  calculateCompletion(): string {
    let total: number = 0;
    let checkedIn: number = 0;
    this.rooms.forEach(room => {
      room.Guests.forEach(guest => {
        total += 1;
        if (guest.Status == GuestStayStatus.CHECKED_OUT || guest.Status == GuestStayStatus.CHECKED_IN || guest.Status == GuestStayStatus.READY_TO_CHECKIN || guest.Status == GuestStayStatus.VIZA_VALID) {
          checkedIn++;
        }
      })
    })
    return `(${checkedIn}/${total})`
  }

  paymentClick(guest: RoomGuest) {
    SessionStorage.SelectedGuestData = guest
    this.router.navigateByUrl("/balance?showdeposit=true")

  }

  chatIconStyle() {
    let styles = {};
    styles = ChatVisibilityHelper.ChatVisibleStyle
    return styles
  }
  openExcludedServicesDialog() {
    alert("Kivenni")
    this.dialog.open(ExcludedServicesInformationDialogComponent, {
      disableClose: true,
      minWidth: "93vw",
      minHeight: "40vh",
    }).afterClosed().subscribe(result => {
      console.log(result)
    })
  }
}

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-size {
  font-size: 40px;
}

.icon-pack-style {
  background-color: #ececec;
}

.photo-label {
  position: absolute;
  margin: auto;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.image-class {
  margin-top: 50px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

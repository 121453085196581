// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigate-back {
  color: white;
  font-size: 40px;
  margin-top: 0.8em;
}

.header-back {
  background-image: linear-gradient(black, #333333);
}

.logo {
  max-height: 80px;
  max-width: 350px;
  margin: auto;
}

.logo-position {
  line-height: 100px;
  height: 100px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  width: 100vw;
  padding-top: 3rem;
}

.wrapper {
  text-align: center;
}

.button-ok {
  padding-top: 3rem;
}

.timeout-icon {
  color: orange;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

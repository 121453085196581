// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canvas {
  background: white;
  color: black;
  width: 100%;
}

.canvas-wrapper {
  border: 1px solid black;
}

.cdk-global-overlay-wrapper {
  background-color: rgba(0, 0, 0, 0.5607843137) !important;
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  background-color: #fff !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  display: block !important;
  padding: 24px !important;
  border-radius: 20px !important;
  box-sizing: border-box !important;
  overflow: auto !important;
  outline: 0 !important;
  width: 100% !important;
  height: 100% !important;
  min-width: 93vw !important;
  max-width: 93vw !important;
  position: static;
  min-height: inherit !important;
  max-height: inherit !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.room-row {
  background-color: #efefef;
  padding: 0.5em;
}

.guest-name-align {
  padding: 10px 0;
}

.completion-counter {
  color: #a2a2a2;
}

.material-icons {
  font-size: 30px;
  height: 30px;
  width: 30px;
}

.icon-align {
  display: block;
  margin: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

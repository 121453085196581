import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * See https://angular.io/guide/service-worker-devops#bypassing-the-service-worker for more info.
 * This interceptor is used to bypass the service worker when making HTTP requests as there are some cases where the service worker might run into issues.
 * Since we are not using the service worker for caching HTTP requests, we can safely bypass it.
 * Attention: make sure that this header is explicitly allowed (e.g. by your file storage provider else file fetches might fail due to CORS).
 */
@Injectable()
export class ServiceWorkerBypassInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const newRequest = request.clone({ headers: request.headers.append('ngsw-bypass', 'true') });
    return next.handle(newRequest);
  }
}
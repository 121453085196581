
// Angular
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// 3rdparty
import dayjs from 'dayjs';

// Hostware
import { DateSelectorComponent } from '@app/components/date-selector/date-selector.component';
import SessionStorage from '@app/helpers/SessionStorage';
import { Country } from '@app/interfaces/Country';
import { VizaData } from '@app/interfaces/VizaData';
import { ZiplCodes } from '@app/interfaces/ZipCodes';
import { LoadCsvService } from '@app/services/loadCsv.service';
import { SpinnerService } from '@app/services/spinner.service';
import { VizaDataService } from '@app/services/viza-data.service';
import { Subscription, combineLatest, debounceTime } from 'rxjs';
import { GuestDataFields } from '../../../../../froexishared/src/dto/GuestdataFields';
import { SubscriberParameter } from '../../../../../froexishared/src/dto/SubscriberParameter';
import { SubscriberParameterService } from '../../../../../froexishared/src/services/SubscriberParameter.service';
import { TranslateService } from '@ngx-translate/core';
import ChatVisibilityHelper from '@app/helpers/ChatvisibilityHelper';
import { GenericMessage } from '@app/interfaces/generic-message';
import { GenericMessageDialogComponent } from '@app/dialogs/generic-message-dialog/generic-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { YesNoDialogComponent } from '@app/dialogs/yes-no-dialog/yes-no-dialog.component';
import { MatSelect } from '@angular/material/select';
import { MessageType } from "@app/types/Types";

@Component({
  selector: 'viza-data',
  templateUrl: './viza-data.component.html',
  styleUrls: ['./viza-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VizaDataComponent implements OnInit {

  @ViewChild('dateOfEntry') dateOfEntryComponent: DateSelectorComponent;
  @ViewChild('birthDate') birthDateComponent: DateSelectorComponent;
  @ViewChild('documentType')  documentType : MatSelect;

  commonGroup: FormGroup;
  visaRequiredGroup: FormGroup;
  acceptConditionsGroup: FormGroup;
  addressGroup: FormGroup;
  countries: Country[] = [];
  zipCodes: ZiplCodes[] = [];
  dataFields: GuestDataFields[] = [];
  loaded: boolean;
  hasAdditionalDataFields: boolean;
  birthDate: string;
  title: string;
  today = dayjs().format("YYYYMMDD");
  dateOfEntry: string;
  filteredCountry : Country[]=[];
  mrzCcontrols : MrzControl[]=[
    { ControlName: 'FirstName',Touched: false   },
    { ControlName: 'LastName', Touched : false  },
    { ControlName: 'DocumentId', Touched: false },
    { ControlName: 'BirthDate', Touched : false },
    { ControlName: 'DocumentType', Touched : false }


  
  ]

  loadSubscriberParameter$: Subscription;
  constructor(
    private fb: FormBuilder,
    private vizaDataService: VizaDataService,
    private router: Router,
    private spinnerService: SpinnerService,
    private subscriberParameterService: SubscriberParameterService,
    private loadCsvService: LoadCsvService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.commonGroup = this.fb.group({
      BirthDate: new FormControl('', Validators.required),
      FirstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      LastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      FirstNameAtBirth: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      LastNameAtBirth: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      BirthPlace: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      Nationality: new FormControl('', Validators.required),
      MothersFirstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      MothersLastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      DocumentType: new FormControl('', Validators.required),
      DocumentId: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      Sex: new FormControl('', Validators.required)

    })

    this.visaRequiredGroup = this.fb.group({
      VizaNumber: new FormControl('', Validators.required),
      PlaceOfEntry: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      DateOfEntry: new FormControl('', Validators.required)
    })

    this.addressGroup = this.fb.group({
      Country: new FormControl('', Validators.required),
      PostalCode: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('[A-Z0-9][A-Z0-9\s\-]{1,8}[A-Z0-9]')]),      // min=3 max=10   pattern :=[A-Z0-9][A-Z0-9\s\-]{1,8}[A-Z0-9]
      City: new FormControl('', Validators.maxLength(30)),
      StreetAddress: new FormControl('', [Validators.required, Validators.maxLength(40)])
    })

    this.acceptConditionsGroup = this.fb.group({
      Accept: new FormControl(false)
    })

    this.title =
      SessionStorage.ActiveGuestHeaderData().RoomTypeName + " - " +
      SessionStorage.ActiveGuestHeaderData().NumberOfGuests + " fő - " +
      SessionStorage.ActiveGuestHeaderData().GuestName

  }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.dateOfEntry = this.today.substring(2, 4) + this.today.substring(4, 8)

    this.translate.getDefaultLang() === 'hu' ? this.loadCsvService.getDataFromCsv('assets/data/hu-countries.csv', ";", this.countries, 'lang') : this.loadCsvService.getDataFromCsv('assets/data/en-countries.csv', ";", this.countries, 'lang');
    this.filteredCountry = this.countries;

    this.loadCsvService.getDataFromCsv('assets/data/zip-codes.csv', ";", this.zipCodes, 'zipCode');

    combineLatest({
      countryCode: this.addressGroup.get('Country').valueChanges,
      postalCode: this.addressGroup.get('PostalCode').valueChanges
    }).pipe(debounceTime(300))
      .subscribe((result) => {
        if (result.countryCode === 'HU') {
          if (this.zipCodes.find(zipCode => zipCode.zipCode === result.postalCode)) {
            let filterCity = this.zipCodes.find(zipCode => zipCode.zipCode === result.postalCode).City
            this.addressGroup.get('City').setValue(filterCity)
          }
        }
      })

    if (SessionStorage.MrzData !== undefined) {
      //   form with mrz data
      this.commonGroup.controls["BirthDate"].setValue(SessionStorage.MrzData.BirthDate)
      this.commonGroup.controls["FirstName"].setValue(SessionStorage.MrzData.Surname);
      this.commonGroup.controls["LastName"].setValue(SessionStorage.MrzData.Name);
      this.commonGroup.controls["Nationality"].setValue(SessionStorage.MrzData.Country);
      this.commonGroup.controls["Sex"].setValue(SessionStorage.MrzData.Sex);
      SessionStorage.MrzData.DocumentType !== "I" && SessionStorage.MrzData.DocumentType !== "P" ? this.commonGroup.controls["DocumentType"].setValue("I") : this.commonGroup.controls["DocumentType"].setValue(SessionStorage.MrzData.DocumentType)
      this.commonGroup.controls["DocumentId"].setValue(SessionStorage.MrzData.DocumentNumber);
    }

    // if (SessionStorage.GuestAge == 'u14') {
    //   SessionStorage.ActiveLanguage.toLowerCase() == "hu" ? this.commonGroup.controls["Nationality"].setValue('HUN') : this.commonGroup.controls["Nationality"].setValue(this.zipCodes[0].zipCode);
    // }

    this.loadSubscriberParameter$ = this.subscriberParameterService.loadByTypeCode(SessionStorage.GuestData.Subscriber, SessionStorage.GuestData.HotelId, 'DATA_FORM').subscribe(result => {
      const res = result as SubscriberParameter
      this.dataFields = JSON.parse(res.Param_Json_Value) as GuestDataFields[];

      if (this.dataFields.find(item => item.DataCode == "EMAIL").DataNature == "N"
        && this.dataFields.find(item => item.DataCode == "PLATE_NUMBER").DataNature == "N"
        && this.dataFields.find(item => item.DataCode == "PHONE").DataNature == "N"
        && this.dataFields.find(item => item.DataCode == "SIGNATURE").DataNature == "N") {
        this.hasAdditionalDataFields = false
      } else {
        this.hasAdditionalDataFields = true;
      }

      if (this.dataFields.find(item => item.DataCode == "CITY").DataNature !== "M") {
       this.addressGroup.controls["City"].removeValidators(Validators.required);
       }
      if (this.dataFields.find(item => item.DataCode == "ADDRESS").DataNature !== "M") {
        this.addressGroup.controls["StreetAddress"].removeValidators(Validators.required);
      }

      this.loaded = true;
      this.spinnerService.setSpinnerVisibility(false)
    })

  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  sendVizaData() {
    // Country az a 2 jegyes ISO Alpha 2
    let data: VizaData = {
      GuestStayUuId: SessionStorage.SelectedGuestData.GuestStayUuId,
      Hotel3pId: SessionStorage.ReservationInfo.Hotel3pId,
      Address: {
        City: this.addressGroup.controls["City"].value,
        Country: this.countries.find(item => item.Id == this.addressGroup.controls['Country'].value).Id,
        PostalCode: this.addressGroup.controls["PostalCode"].value,
        StreetAddress: this.addressGroup.controls["StreetAddress"].value,

      },
      ScannedData: {
        Birthdate: '',
        BirthPlace: '',
        DateOfEntry: '', //this.visaRequiredGroup.controls["DateOfEntry"].value,
        DocumentId: '',
        DocumentType: '',
        FirstName: '',
        FirstNameAtBirth: '',
        Gender: '',
        LastName: '',
        LastNameAtBirth: '',
        MothersFirstName: '',
        MothersLastName: '',
        Nationality: '',
        Number: '',
        PlaceOfEntry: ''
      },
      ManualData: {
        Birthdate: this.birthDateComponent.selectedDateValue(), // this.commonGroup.controls['BirthDate'].value,
        BirthPlace: this.commonGroup.controls['BirthPlace'].value,
        DateOfEntry: '', //this.visaRequiredGroup.controls["DateOfEntry"].value,
        DocumentId: this.commonGroup.controls['DocumentId'].value,
        DocumentType: this.commonGroup.controls['DocumentType'].value,
        FirstName: this.commonGroup.controls['LastName'].value,
        FirstNameAtBirth: this.commonGroup.controls['LastNameAtBirth'].value,
        Gender: this.commonGroup.controls['Sex'].value,
        LastName: this.commonGroup.controls['FirstName'].value,
        LastNameAtBirth: this.commonGroup.controls['FirstNameAtBirth'].value,
        MothersFirstName: this.commonGroup.controls['MothersLastName'].value,
        MothersLastName: this.commonGroup.controls['MothersFirstName'].value,
        Nationality: this.countries.find(item => item.Id == this.commonGroup.controls['Nationality'].value).Id,
        Number: '',
        PlaceOfEntry: ''
      }
    }

    this.vizaDataService.SendVizaData(data).subscribe({
      next: (result => {
        let messageData: GenericMessage
        messageData = { messageText: this.translate.instant('additional_data.details_save_ok'), messageType: 'INFORMATION', showIcon:false }
        this.showResultDialog(messageData, false)
      }),
      error(err) {
        let messageData: GenericMessage
        messageData = { messageText: this.translate.instant('common.network_error'), messageType: 'ERROR', showIcon:false }
        this.showResultDialog(messageData, true)
      },
    })

  }

  onKeyNationality(event) {
    this.filteredCountry = this.search(event.target.value);
  }
  onKeyCountry(event) {
    this.filteredCountry = this.search(event.target.value);
  }

  search(value: string) {
    let filter = value.toLowerCase()
    return this.countries.filter(country => country.Name.toLocaleLowerCase().includes(filter));
  }
  //#endregion =================================================================================================================

  //#region =============== Validation =========================================================================================
  visaRequired() {
    let selectedCountry: Country;
    if (this.countries.length > 0 && this.commonGroup.controls["Nationality"].value !== '') {
      if (this.commonGroup.controls["Nationality"].value === "") {
        selectedCountry = this.countries[0]
      } else {
        selectedCountry = this.countries.find(item => item.Id == this.commonGroup.controls["Nationality"].value)
      }
      if (selectedCountry !== undefined) {
        return selectedCountry.IsEu == 'FALSE'
      } else {
        return false
      }

    } else {

      return false;
    }

  }

  allFieldsValid(): boolean {
    let visaGroupValid: boolean;
    let commonGroupValid: boolean;
    let acceptConditionsGroupValid: boolean;

    commonGroupValid = this.commonGroup.valid;
    if (this.visaRequired()) {
      visaGroupValid = this.visaRequiredGroup.valid;
    } else {
      visaGroupValid = true;
    }
    if (!this.hasAdditionalDataFields) {

      return commonGroupValid && visaGroupValid && this.addressGroup.valid && this.acceptConditionsGroup.controls["Accept"].value == true


    } else {
      return commonGroupValid && visaGroupValid && this.addressGroup.valid;

    }

  }

  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  fieldMustShow(dataCode: string): boolean {
    const item = this.dataFields.find(item => item.DataCode == dataCode);
    return (item.DataNature == 'N' ? false : true)
  }

  requiredChar(data_code: string): string {
    const field = this.dataFields.find(item => item.DataCode == data_code);
    return field.DataNature == "M" ? '*' : ''
  }

  chatIconStyle() {
    let styles = {};
    styles = ChatVisibilityHelper.ChatVisibleStyle
    return styles
  }

  showResultDialog(messageData: GenericMessage, hasError: boolean) {
    const okRef = this.dialog.open(GenericMessageDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      maxWidth: "auto",
      data: messageData
    })

    okRef.afterClosed().subscribe(data => {
      if (!hasError) {
        if (this.hasAdditionalDataFields) {
          this.router.navigateByUrl('/additionaldata')
        } else {
          this.router.navigateByUrl('/checkinstatus')
        }
      } else {
        this.router.navigateByUrl('/checkinstatus')
      }
      
    })
  }

  controlValueChanged(controlName : string) {
    let messageData: GenericMessage ;
    messageData =  { messageText: this.translate.instant('viza_data.field_modify_warning'), messageType: 'WARNING', showIcon : true }
    if( !this.mrzCcontrols.find(control=> control.ControlName == controlName).Touched) {
      this.dialog.open(YesNoDialogComponent, {
        disableClose: true,
        hasBackdrop: true,
        width: "85vw",
        data: messageData
      }).afterClosed().subscribe(data => {
        if (data) {
          this.mrzCcontrols.find(control=> control.ControlName == controlName).Touched = true;
        }
        this.birthDateComponent.closeCombo();
        this.documentType.close();
      })
    }
  }

  //#endregion =================================================================================================================
}

class MrzControl {
  ControlName : string;
  Touched : boolean;
}



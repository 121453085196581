// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 100%;
}

.expo {
  font-size: 90px;
  z-index: 20000;
}

.expo-container {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.approve-container {
  position: fixed;
  left: 0%;
  right: 0%;
  bottom: 0;
  /* transform: translate(-50%, -50%); */
  margin: 0 auto;
  width: 100%;
  background-color: #434343;
  height: 100px;
}

.preview {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.control-button {
  color: white;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}

.mat-icon {
  height: 90px;
  width: 90px;
  font-size: 90px;
  line-height: 90px;
  margin-top: -7px;
}

.mat-icon-button {
  width: auto;
  height: auto;
}

.done-button {
  color: #41c300;
}

.cancel-button {
  color: white;
}

.vertical-align-image {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mat-mdc-icon-button {
  --mdc-icon-button-state-layer-size: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Branding } from "@app/dto/Branding";
import { GuestLoginResponse } from "@app/dto/GuestLoginResponse";
import { mrzfields } from "@app/dto/IdClassifierResult";
import { ReservationInfo, RoomGuest } from "@app/dto/ReservationInfo";
import { UrlParams } from "@app/dto/UrlParams";
import { GuestHeaderInfo } from "@app/interfaces/GuestHeaderInfo";
import { Age, CaptureMode } from "@app/types/Types"
import dayjs, { Dayjs } from "dayjs";
import { BrandingDto } from "../../../../froexishared/src/dto/BrandingDto";
import { InvoiceDetailsFormData } from "@app/dto/InvoiceDetailsFormData";

import { VizaData, VizaPart } from "@app/interfaces/VizaData";


export default class SessionStorage {

  constructor() { }

  /*  --------------------------------------------------------------  */
  public static set MrzData(info: mrzfields | undefined) {
    if (info) {
      sessionStorage.setItem('mrz', JSON.stringify(info));
    }
    else {
      sessionStorage.setItem('mrz', undefined);
    }
  }
  public static get MrzData(): mrzfields | undefined {
    if ((sessionStorage.getItem('mrz')) != "undefined") {
      return JSON.parse(sessionStorage.getItem('mrz')) as mrzfields;
    }
    else {
      return undefined
    }
  }
  public static set FaceImageFromCard(base64Image: string) {
    sessionStorage.setItem('fifc', base64Image)
  }
  public static get FaceImageFromCard(): string {
    return sessionStorage.getItem('fifc')
  }

  public static set Selfie(base64Image: string) {
    sessionStorage.setItem('selfie', base64Image)
  }
  public static get Selfie(): string {
    return sessionStorage.getItem('selfie')
  }
  /*  --------------------------------------------------------------  */


  public static setImage(mode: CaptureMode, base64Image: string) {
    sessionStorage.setItem(mode, base64Image);
  }
  public static getImage(mode: CaptureMode): string | undefined {
    return sessionStorage.getItem(mode)
  }

  public static set BrandingData(data: BrandingDto) {
    sessionStorage.setItem('branding', JSON.stringify(data))
  }
  public static get BrandingData(): BrandingDto {
    return JSON.parse(sessionStorage.getItem('branding')) as BrandingDto
  }

  public static set ActiveLanguage(lngCode: string) {
    sessionStorage.setItem("lng", lngCode)
  }

  public static get ActiveLanguage(): string {
    return sessionStorage.getItem('lng')
  }

  public static set ChatVisibility(value: string) {
    sessionStorage.setItem("cvis", value)
  }

  public static get ChatVisibility(): string {
    return sessionStorage.getItem('cvis')
  }
  public static set UrlParams(params: UrlParams) {
    sessionStorage.setItem("url", JSON.stringify(params))
  }

  public static get UrlParams(): UrlParams {
    return JSON.parse(sessionStorage.getItem('url')) as UrlParams;
  }

  public static set GuestData(token: GuestLoginResponse) {
    sessionStorage.setItem('gtr', JSON.stringify(token))
  }

  public static get GuestData(): GuestLoginResponse {
    return JSON.parse(sessionStorage.getItem('gtr')) as GuestLoginResponse
  }

  public static set SelectedGuestData(data: RoomGuest) {
    sessionStorage.setItem('sgd', JSON.stringify(data))
  }

  public static get SelectedGuestData(): RoomGuest {
    return JSON.parse(sessionStorage.getItem('sgd')) as RoomGuest
  }



  public static set ReservationInfo(info: ReservationInfo) {
    sessionStorage.setItem('rinf', JSON.stringify(info));
  }
  public static get ReservationInfo(): ReservationInfo {
    return JSON.parse(sessionStorage.getItem('rinf')) as ReservationInfo;
  }

  public static get GuestAge(): Age {
    return sessionStorage.getItem('age') as Age
  }

  public static set GuestAge(age: Age) {
    sessionStorage.setItem('age', age)
  }


  public static guestNumber(): number {
    let guestCount: number = 0;
    SessionStorage.ReservationInfo.Rooms.forEach(room => {
      guestCount += room.Guests.length
    })
    return guestCount
  }

  public static get RoomNumber(): number {
    return SessionStorage.ReservationInfo.Rooms.length;
  }
  public static nights(): number {
    const start = dayjs(SessionStorage.ReservationInfo.Rooms[0].Guests[0].Arrival);
    const end = dayjs(SessionStorage.ReservationInfo.Rooms[0].Guests[0].Departure);
    const days = end.diff(start, 'day')
    return days;
  }

  public static ActiveGuestHeaderData(): GuestHeaderInfo {
    const selectedGuest = JSON.parse(sessionStorage.getItem('sgd')) as RoomGuest
    const resInfo = JSON.parse(sessionStorage.getItem('rinf')) as ReservationInfo;
    let headerInfo = new GuestHeaderInfo();
    resInfo.Rooms.forEach(room => {
      room.Guests.forEach(guest => {
        if (guest.GuestStayUuId == selectedGuest.GuestStayUuId) {
          headerInfo.GuestName = guest.Name;
          headerInfo.RoomId = room.RoomId;
          headerInfo.RoomTypeName = room.RoomType;
          headerInfo.NumberOfGuests = room.Guests.length;

        }
      })
    })

    return headerInfo;
  }

  public static get VizaPart(): VizaPart {
    return JSON.parse(sessionStorage.getItem('vizap')) as VizaPart;
  }
  public static set VizaPart(data: VizaPart) {
    sessionStorage.setItem('vizap', JSON.stringify(data))
  }


  public static get InvoiceDetails(): InvoiceDetailsFormData {
    return JSON.parse(sessionStorage.getItem('idt')) as InvoiceDetailsFormData;
  }

  public static set InvoiceDetails(data: InvoiceDetailsFormData) {
    sessionStorage.setItem('idt', JSON.stringify(data))
  }

  public static get RetryCountImage() : number {
    // ha még azelőtt kérdeznénkle , hogy írtunk volna bele, kap egy deafult 0 értéket
    return parseInt(sessionStorage.getItem('rtci'))
  }

  public static set RetryCountImage(cnt: number) {
    sessionStorage.setItem('rtci', cnt.toString())
  }

  public static get RetryCountMrz() : number {
    // ha még azelőtt kérdeznénkle , hogy írtunk volna bele, kap egy deafult 0 értéket
    return parseInt(sessionStorage.getItem('rtcm'))
  }

  public static set RetryCountMrz(cnt: number) {
    sessionStorage.setItem('rtcm', cnt.toString())
  }


  public static get RetryCountSelfie() : number {
    // ha még azelőtt kérdeznénkle , hogy írtunk volna bele, kap egy deafult 0 értéket
    return parseInt(sessionStorage.getItem('srtc'))
  }

  public static set RetryCountSelfie(cnt: number) {
    sessionStorage.setItem('srtc', cnt.toString())
  }


  public static get HasExludedService() : string {
    // ha még azelőtt kérdeznénkle , hogy írtunk volna bele, kap egy deafult 0 értéket
    return sessionStorage.getItem('hexs')
  }

  public static set HasExludedService(hasEx: string) {
    sessionStorage.setItem('hexs', hasEx)
  }

  public static get HasOnlyExludedServices() : string {
    // ha még azelőtt kérdeznénkle , hogy írtunk volna bele, kap egy deafult 0 értéket
    return sessionStorage.getItem('hoexs')
  }

  public static set HasOnlyExludedServices(hasEx: string) {
    sessionStorage.setItem('hoexs', hasEx)
  }

  public static get SelectedPaymentMode() : string {
    // ha még azelőtt kérdeznénkle , hogy írtunk volna bele, kap egy deafult 0 értéket
    return sessionStorage.getItem('spm')
  }

  public static set SelectedPaymentMode(hasEx: string) {
    sessionStorage.setItem('spm', hasEx)
  }

  public static get SelectedPaymentType() : string {
    // ha még azelőtt kérdeznénkle , hogy írtunk volna bele, kap egy deafult 0 értéket
    return sessionStorage.getItem('spt')
  }

  public static set SelectedPaymentType(type: string) {
    sessionStorage.setItem('spt', type)
  }


  public static get UseDeposit() : string {
    // ha még azelőtt kérdeznénkle , hogy írtunk volna bele, kap egy deafult 0 értéket
    return sessionStorage.getItem('usedep')
  }

  public static set UseDeposit(useDeposit: string) {
    sessionStorage.setItem('usedep', useDeposit)
  }

  public static get IpAddress() : string {
    // ha még azelőtt kérdeznénkle , hogy írtunk volna bele, kap egy deafult 0 értéket
    return sessionStorage.getItem('ipa')
  }

  public static set IpAddress(ipAddress: string) {
    sessionStorage.setItem('ipa', ipAddress)
  }



}


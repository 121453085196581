// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-icon {
  color: red;
}

.information-icon {
  color: green;
}

.warning-icon {
  color: #ffca28;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJpZ2Zpc2gtcGF5bWVudC1oYW5kbGVyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsVUFBQTtBQUNGOztBQUVBO0VBQ0UsWUFBQTtBQUNGOztBQUVBO0VBQ0UsY0FBQTtBQUNGIiwiZmlsZSI6ImJpZ2Zpc2gtcGF5bWVudC1oYW5kbGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmVycm9yLWljb24ge1xuICBjb2xvcjogcmVkO1xufVxuXG4uaW5mb3JtYXRpb24taWNvbiB7XG4gIGNvbG9yOiBncmVlbjtcbn1cblxuLndhcm5pbmctaWNvbiB7XG4gIGNvbG9yOiAjZmZjYTI4XG59XG5cblxuIl19 */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/pages/bigfish-payment-handler/bigfish-payment-handler.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,cAAA;AACF;AACA,4fAA4f","sourcesContent":[".error-icon {\n  color: red;\n}\n\n.information-icon {\n  color: green;\n}\n\n.warning-icon {\n  color: #ffca28\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

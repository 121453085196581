// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.bigfish-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.payment-logo {
  height: 23px;
  margin-right: 8px;
  margin-left: 8px;
}

.bigfish-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; /* vagy konkrét magasság, pl. 200px */
}

mat-radio-button {
  display: flex;
  align-items: center; /* Vertikálisan középre igazítja a radio button tartalmát */
  margin: 5px 0; /* Opciók közötti távolság beállítása */
}

.col-horizontal-center {
  display: flex;
  align-items: center; /* Vertikálisan középre igazítja a div tartalmát (kép és szöveg) */
}

.col-horizontal-center-images {
  display: flex;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  /* justify-items: center; */
}

.label-align {
  margin-left: 8px;
}

.payment-logo-3 {
  height: 23px;
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 1em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

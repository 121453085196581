// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question {
  display: flex;
  justify-content: center;
}

.dialog-container {
  padding: 2em;
  box-shadow: var(--mdc-dialog-container-elevation, 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12));
}

.mdc-dialog__container {
  border-radius: 20px;
}

.mat-white {
  color: #565656 !important;
  border-style: solid !important;
  border-color: #565656 !important;
  border-width: 2px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  border-radius: 20px !important;
  min-width: 150px;
  background-color: white !important;
}

.mat-green {
  border-width: 2px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  border-radius: 20px !important;
  min-width: 150px;
  background-image: linear-gradient(#57bf42, #5bac4b);
  color: white !important;
  border-color: #65c551 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

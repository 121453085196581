// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  padding: 0px 15px 0px 15px;
  height: 100px;
  position: fixed;
  top: 0;
  width: 100%;
  border-radius: 0 0 10px 10px;
  z-index: 10;
}

.footer {
  padding: 0px 15px -1px 15px;
  width: 100%;
  height: 35px;
  z-index: 10;
  position: fixed;
  bottom: -1px;
  border-radius: 10px 10px 0 0;
}

.outlet {
  margin: 87px 0px 51px 0px;
  overflow: scroll;
  overflow-y: hidden;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-green {
  background-color: #00B031;
  color: white;
}

.mat-red {
  background-color: red;
  color: white;
}

.error-icon {
  font-size: 60px;
  text-align: center;
  color: red;
}

.check-icon {
  font-size: 60px;
  text-align: center;
  color: #41c300;
}

.warning-icon {
  font-size: 60px;
  text-align: center;
  color: #ffca28;
}

.mat-icon {
  height: auto;
  width: auto;
}

.mat-dialog-actions {
  margin-bottom: auto;
}

.mat-dialog-content {
  margin: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing-container {
  font-family: "Open Sans" !important;
  height: calc(100dvh - 138px);
  display: flex;
  flex-direction: column;
  position: relative;
}

.billing-header {
  padding: 4rem 1.5rem 1.5rem;
  font-weight: bold;
  color: hsl(0, 0%, 40%);
}

.balance-container,
.deposit-container {
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1rem 0.25rem;
  margin: 1em;
}

.charge-wrapper,
.transaction-wrapper {
  display: flex;
  width: 100%;
  padding: 0.5rem;
  border-bottom: 1px solid hsla(0, 0%, 40%, 0.4);
}

.charge-wrapper:last-child,
.transaction-wrapper:last-child {
  border-bottom: none;
}

.charge-details,
.transaction-details {
  width: 100%;
}

.charge-details:nth-child(1),
.transaction-details:nth-child(1) {
  font-weight: bold;
  color: hsl(0, 0%, 40%);
}

.charge-details:nth-child(2),
.transaction-details:nth-child(2) {
  width: 10%;
}

.charge-details:last-child,
.transaction-details:last-child {
  text-align: end;
  width: 50%;
}

.pay-container {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.total-amount {
  padding-bottom: 2rem;
  height: 150px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0px;
  border-radius: 20px 20px 0px 0px !important;
  padding-top: 1em;
}

.total-amount-txt {
  padding-right: 2rem;
}

.color-red {
  color: red;
}

.billing-logo {
  padding-top: 2.5rem;
  padding-right: 0.5rem;
  width: 100%;
}

.billing-image-max {
  max-width: 150px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

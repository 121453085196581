// Angular
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GenericMessageDialogComponent } from '@app/dialogs/generic-message-dialog/generic-message-dialog.component';
import { IdClassifierResult } from '@app/dto/IdClassifierResult';
import SessionStorage from '@app/helpers/SessionStorage';
import { GenericMessage } from '@app/interfaces/generic-message';
import { BottomBarService } from '@app/services/bottom-bar.service';
import { HwComputerVisionService } from '@app/services/hw-computer-vision.service';
import { ImageCaptureService } from '@app/services/image-capture-service';
import { CaptureMode, MessageType } from '@app/types/Types';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
// 3rdparty
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
// Hostware
import DateHelper from '@app/helpers/DateHelper';
import { VizaData } from '@app/interfaces/VizaData';
import { SpinnerService } from '@app/services/spinner.service';
import { DetectionLimitErrorDialogComponent } from '@app/dialogs/detection-limit-error-dialog/detection-limit-error.dialog.component'

@AutoUnsubscribe()
@Component({
  selector: 'photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit, OnDestroy {
  idClassifier$: Subscription;
  faceMatch$: Subscription;
  hasImage: boolean = false;
  hasSelfie: boolean = false;
  
  faceMatch: boolean = false;
  message: string = '';

  captureMode: CaptureMode = 'DOCUMENT';   // available values: "d1"-document side1, "d2"-document side2   "s"-selfie
  imageWidth: number = 1024;
  spinnerVisibility: boolean;
  _width = window.innerWidth;
  _height = window.innerHeight;
  landscape: boolean;
  isNewPhoto: boolean = environment.isNewPhoto;

  constructor(
    private captureService: ImageCaptureService,
    private hwCwService: HwComputerVisionService,
    private bottomBarService: BottomBarService,
    private translate: TranslateService,
    private errorDialog: MatDialog,
    private router: Router,
    private spinnerService: SpinnerService,
    private breakPointObserver: BreakpointObserver,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe((state: BreakpointState) => {
      state.breakpoints[Breakpoints.HandsetLandscape] ? this.landscape = true : this.landscape = false;
    })

    if (SessionStorage.FaceImageFromCard !== '') this.hasImage = true;
    if (SessionStorage.Selfie !== '') this.hasSelfie = true;

    this.bottomBarService.setBottomBarVisibility(false)
    SessionStorage.RetryCountSelfie = 0;
    SessionStorage.RetryCountImage = 0
    SessionStorage.RetryCountMrz = 0;


    if (SessionStorage.MrzData && this.hasImage) {
      this.captureMode = 'SELFIE'
      this.message = this.translate.instant('photo.make_a_selfie')
    } else {
      if ((!this.hasImage && SessionStorage.MrzData) || (!this.hasImage && !SessionStorage.MrzData)) {
        this.message = this.translate.instant('photo.read_faceimage_side');
      }
      if (this.hasImage && !SessionStorage.MrzData) {
        this.message = this.translate.instant('photo.read_mrz_side');
      }
    }

    this.captureService.imageApproved.subscribe(base64Img => {

      this.message = "";
      if (this.captureMode !== 'SELFIE') {
        this.spinnerService.setSpinnerVisibility(true);
        this.spinnerVisibility = true;
        this.idClassifier$ = this.hwCwService.idClassifier(base64Img).subscribe(
          {
            next: (result) => {
              this.spinnerVisibility = false;
              this.spinnerService.setSpinnerVisibility(false);
              const res = result as IdClassifierResult;
              // van kép, és mégnem volt előzőleg tárolva
              if (res.FaceImage !== "" && SessionStorage.FaceImageFromCard == '') {
                SessionStorage.FaceImageFromCard = res.FaceImage;
                this.hasImage = true;
              }

              if (!SessionStorage.MrzData && res.MrzFields["Surname"]) {
                SessionStorage.MrzData = res.MrzFields;
              }

              if (!SessionStorage.MrzData && !this.hasImage) {
                SessionStorage.RetryCountMrz+=1
                SessionStorage.RetryCountImage+=1
              }

              if (!SessionStorage.MrzData && this.hasImage) {
                SessionStorage.RetryCountMrz+=1
              }
              if (SessionStorage.MrzData && !this.hasImage) {
                SessionStorage.RetryCountImage+=1
              }
               
              if(SessionStorage.RetryCountImage == 3 || SessionStorage.RetryCountMrz == 3) {
                this.showDetectionLimitError();
              }
              if (!SessionStorage.MrzData && !this.hasImage) {
                this.showDialog(this.translate.instant('photo.document_process_error'), 'ERROR')
              }

                //     this.showDetectionLimitError();  ) {
                //     this.showDetectionLimitError();  

              // if (!SessionStorage.MrzData && !this.hasImage) {
              //   SessionStorage.RetryCount += 1;
              //   if(SessionStorage.RetryCount == 3) {
              //     this.showDetectionLimitError();  
              //   } else {
              //     this.showDialog(this.translate.instant('photo.document_process_error'), 'ERROR')
              //   }
              // }

              if (SessionStorage.MrzData && this.hasImage) {
                this.captureMode = 'SELFIE'
                this.message = this.translate.instant('photo.make_a_selfie');
              } else {
                if ((!this.hasImage && SessionStorage.MrzData) || (!this.hasImage && !SessionStorage.MrzData)) {
                  this.message = this.translate.instant('photo.read_faceimage_side');
                }
                if (this.hasImage && !SessionStorage.MrzData) {
                  this.message = this.translate.instant('photo.read_mrz_side');
                }
              }
            },
            error: (err: HttpErrorResponse) => {
              this.spinnerService.setSpinnerVisibility(false);
              this.spinnerVisibility = false;
              this.showDialog(this.translate.instant('photo.document_process_error'), 'ERROR')
              this.showMessage();
            } 
          }
        )
      } else {
        SessionStorage.Selfie = base64Img;
         this.hasSelfie = true;
        if (this.hasImage && this.hasSelfie && SessionStorage.MrzData) {
          this.spinnerService.setSpinnerVisibility(true);
          this.spinnerVisibility = true;
          this.faceMatch$ = this.hwCwService.faceMatch(SessionStorage.FaceImageFromCard, SessionStorage.Selfie).subscribe({
            next: (result) => {
              this.spinnerService.setSpinnerVisibility(false);
              this.spinnerVisibility = false;

              if (result["CompResult"] === 'False') {
                SessionStorage.RetryCountSelfie += 1;
                if(SessionStorage.RetryCountSelfie == 3) {
                  this.showDetectionLimitError();  
                } else {
                  let messageData: GenericMessage = { messageText: this.translate.instant('photo.face_compare_error'), messageType: 'ERROR' }
                  const dialogRef = this.errorDialog.open(GenericMessageDialogComponent, {
                    disableClose: true,
                    hasBackdrop: true,
                    width: "85vw",
                    data: messageData
                  })
                }
                this.hasSelfie = false;
              }
              else {
                this.router.navigateByUrl('/idverificationok')
              }
            },
            error: (err) => {
              this.spinnerVisibility = false;
              this.spinnerService.setSpinnerVisibility(false);
           
            }
          })
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.faceMatch$?.unsubscribe();
    this.idClassifier$?.unsubscribe();
  }

  setCaptureMode(captureMode: CaptureMode) {
    this.captureService.startCapture.emit(captureMode)
    this.captureMode = captureMode;
  }

  showMessage() {
    if (SessionStorage.MrzData && this.hasImage) {
      this.message = this.translate.instant('photo.make_a_selfie')
    } else {
      if ((!this.hasImage && SessionStorage.MrzData) || (!this.hasImage && !SessionStorage.MrzData)) {
        this.message = this.translate.instant('photo.read_faceimage_side');
      }
      if (this.hasImage && !SessionStorage.MrzData) {
        this.message = this.translate.instant('photo.read_mrz_side');
      }

    }
  }

  dataURItoBlob(dataURI): Blob {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

  }

  sendVizaData() {
    let data: VizaData = {
      GuestStayUuId: SessionStorage.SelectedGuestData.GuestStayUuId,
      Hotel3pId: SessionStorage.ReservationInfo.Hotel3pId,
      Address: {
        City: '',
        Country: '',
        PostalCode: '',
        StreetAddress: '',

      },
      ScannedData: {
        Birthdate: DateHelper.FormatDate(SessionStorage.MrzData.BirthDate),
        BirthPlace: '',
        DateOfEntry: '',
        DocumentId: SessionStorage.MrzData.DocumentNumber,
        DocumentType: '',
        FirstName: SessionStorage.MrzData.Name,
        FirstNameAtBirth: '',
        Gender: SessionStorage.MrzData.Sex,
        LastName: SessionStorage.MrzData.Surname,
        LastNameAtBirth: '',
        MothersFirstName: '',
        MothersLastName: '',
        Nationality: SessionStorage.MrzData.Nationality,
        Number: '',
        PlaceOfEntry: ''
      },
      ManualData: {
        Birthdate: '',
        BirthPlace: '',
        DateOfEntry: '',
        DocumentId: '',
        DocumentType: '',
        FirstName: '',
        FirstNameAtBirth: '',
        Gender: '',
        LastName: '',
        LastNameAtBirth: '',
        MothersFirstName: '',
        MothersLastName: '',
        Nationality: '',
        Number: '',
        PlaceOfEntry: ''
      }
    }

    // this.vizaDataService.SendVizaData(data).subscribe(result => {
    // console.log(result);
    this.router.navigateByUrl('/idverificationok')
    // })
  }

  showDialog(messageText: string, type: MessageType) {
    let messageData: GenericMessage = { messageText, messageType: type }
    this.errorDialog.open(GenericMessageDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "85vw",
      data: messageData
    })
  }

  //region style functions
  faceImageWrapperStyle() {
    let styles = {};
    return styles;
  }
  mrzWrapperStyle() {
    let styles = {};
    return styles;
  }
  faceImageIconStyle() {
    let styles = {};
    return styles;
  }
  mrzIconStyle() {
    let styles = {};
    return styles;
  }

  selfieWrapperStyle() {
    let styles = {};
    return styles;
  }
  selfieIconStyle() {
    let styles = {};
    return styles;
  }


  hasMrz(): boolean {
    return SessionStorage.MrzData !== undefined ? true : false
  }

  showDetectionLimitError() {
    const dialogRef = this.dialog.open(DetectionLimitErrorDialogComponent,
      {
        disableClose: true,
        hasBackdrop: true,
        minWidth: '100vw',
        minHeight: '100vh'
      })

    dialogRef.afterClosed().subscribe(forward => {
      SessionStorage.RetryCountImage = 0;
      SessionStorage.RetryCountMrz = 0;
      SessionStorage.RetryCountSelfie = 0;
      if (forward) {
        this.router.navigateByUrl('/vizadata')
      }
    })
  }

  imagePath() {
    if (SessionStorage.MrzData && this.hasImage) {
      return '../../../assets/images/selfie.png';
    } else {
      if ((!this.hasImage && SessionStorage.MrzData) || (!this.hasImage && !SessionStorage.MrzData)) {
        return '../../../assets/images/front.png';
      }
      if (this.hasImage && !SessionStorage.MrzData) {
        return '../../../assets/images/back.png';
      }

    }
  }
  //#endregion
}
// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0 !important;
  padding-top: 1em !important;
  padding-right: 0.1em !important;
  padding-bottom: 0.5em !important;
  padding-left: 0.3em !important;
  border: none;
}

.mat-select-arrow {
  margin: 0 0px !important;
  margin-top: 0.5em !important;
}

.error-label {
  margin-top: -1em;
  margin-bottom: 2em;
}

.hw-error {
  color: red;
  font-weight: 500;
  font-family: "Open Sans";
  font-size: 14px;
}

.cdk-overlay-pane {
  max-width: inherit !important;
}

.material-icons {
  font-size: 30px;
  height: 30px;
  width: 30px;
}

.icon-align {
  display: block;
  margin: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
